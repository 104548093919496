import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './App.css';

import SolidApp from './App';



ReactDOM.render(

    <SolidApp />,
      document.getElementById('root')
);